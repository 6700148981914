/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Function to clear form fields on unload. Prevents back-button caching.
        $( window ).unload(function() {
          // This won't affect hidden fields, or clear fields (just revert them to default).
          // $(this)[0].reset();

          // This is a forcible 'set to null'
          $(':input').not(':button, :submit, :reset, :hidden, :checkbox, :radio').val('');
          $(':checkbox, :radio').prop('checked', false);
        });





      },
      finalize: function() {

        //Main nav
        var header$ = $('#head_main_wrap');

        $('#btn_toggle_search').click(function(event){
          header$.toggleClass('search_open').removeClass('nav_open');
          $(':eq(0)').removeAttr('id');
          if(header$.hasClass('search_open')) $('#inpt_search_main_s').focus();
        });
    
        $('#btn_nav').click(function(event){
          header$.toggleClass('nav_open').removeClass('search_open');
    
          if(header$.hasClass('nav_open')) $(':eq(0)').attr('id', 'mobile_nav_scroll_lock');
          else $(':eq(0)').removeAttr('id');
        });
    
        $('.parent0, .parent1').click(function(event){
          if($(window).width() > 980) return true;
    
          event.preventDefault();
          $(this).parent('li').toggleClass('open');
        });
        $('.parent2').click(function(event){
          event.preventDefault();
    
          if($(window).width() > 980) return false;
          
          $(this).parent('li').toggleClass('open');
        });


        $('.sidebar-btn').click(function (event) {
          $('#product-sidebar').toggleClass('hide');
          $(this).toggleClass('active');
        });


        // Remove tab index of button
        $(".woocommerce-message a").removeAttr("tabindex");

        var slides = document.querySelectorAll('#slider .slide');

        var slideContainer = document.getElementById('slider-cell');

        if (slides.length > 0) {
          const slider = tns({
            container: '#slider',
            items: 1,
            navContainer: "#sliderNav",
            nav: true,
            controls : false,
            autoplay: true,
            speed: 3000,
            autoplayButtonOutput: false,
            mouseDrag: true
          });

          $("#slider-ow").hover(function() {
            var hoverContainer = document.getElementById('slider-ow');
            slider.pause();
            console.log("slider pause");

            if(hoverContainer.classList.contains("play")){
              hoverContainer.classList.remove("play");
            }

            hoverContainer.classList.add("slide-overlay","pause");

            setTimeout(
            function()
            {
              hoverContainer.classList.remove("slide-overlay", "pause", "play");
              slider.pause();
            }, 3000);

          }, function() {
            var hoverContainer = document.getElementById('slider-ow');
            slider.play();
            console.log("slider play");

            if(hoverContainer.classList.contains("pause")){
              hoverContainer.classList.remove("pause");
            }

            hoverContainer.classList.add("slide-overlay","play");

            setTimeout(
            function()
            {
              hoverContainer.classList.remove("slide-overlay", "pause", "play");
              slider.play();
            }, 3000);
          });

          $("#slider-cell").focusin(function() {
            slider.pause();
            console.log("slider pause");
            slideContainer.classList.add("slide-overlay","pause");

            setTimeout(
            function()
            {
              slideContainer.classList.remove("slide-overlay", "pause", "play");
              slider.pause();
            }, 3000);
          });

          $("#slider-cell").focusout(function() {
            slider.play();
            console.log("slider play");
            slideContainer.classList.add("slide-overlay","play");

            setTimeout(
            function()
            {
              slideContainer.classList.remove("slide-overlay", "pause", "play");
              slider.play();
            }, 3000);
          });
        }
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        if(document.querySelectorAll('.home_slide').length){
          var home_slider = tns({
            container: '#home_slider',
            mode: 'gallery',
            speed: 2000,
            autoplay: true,
            autoplayTimeout: 5000,
            mouseDrag: true,
            autoplayButtonOutput: false,
            controls: false,
            nav: true,
            navContainer: '#home_slider_links',
          });
        }

        if(document.querySelectorAll('#home_new_in_slider .product_card_simple').length){
          var home_new_in_slider = tns({
            container: '#home_new_in_slider',
            items: 1.5,
            gutter: 25,
            speed: 200,
            autoplay: false,
            mouseDrag: true,
            autoplayButtonOutput: false,
            controls: true,
            prevButton: '#btn_home_new_in_prev',
            nextButton: '#btn_home_new_in_next',
            nav: false,
            loop: false,
            rewind: true,
            responsive: {
              480: {
                items: 2.5
              },
              650: {
                items: 3.5
              },
              900: {
                items: 4.5
              },
            },
          });
        }
      }
    },
    // About us page, note the change from about-us to about_us.
    'product_template_default': {
      init: function() {
        // JavaScript to be fired on the home page


      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS




      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


// Product archive gallery
(function($){
	//v1.2
	$.fn.productArchiveGallery = function(settings){
		return this.each(function(n){
			var settings = $.extend({
				numImages: $(this).children().length,
				currImage: 0,
				delay: 1500,
        tick: null,
        this$: $(this),
    	},settings||{});
			
			function gotoImage(imageNum){
        settings.this$.children().removeClass('selected');
        settings.this$.children().eq(imageNum).addClass('selected');
				settings.currImage = imageNum;
			}
			
			function gotoNextImage(){
        nextSlide = settings.currImage+1>settings.numImages-1 ? 0 : ++settings.currImage;
				gotoImage(nextSlide);
			}
			
			// function gotoPrevImage(){
			// 	prevImage = settings.currImage==0 ? settings.numImages-1 : --settings.currImage;
			// 	gotoImage(prevImage);
			// }
			
			function rotate(){
        gotoNextImage();
        settings.tick = window.setInterval(gotoNextImage, settings.delay);
			}
			
			function stopRotate(){
				window.clearInterval(settings.tick);
			}

      $(this).on('mouseenter', function(event){
        rotate();
      });
      $(this).on('mouseleave', function(event){
        stopRotate();
        gotoImage(0);
      });			
		});
	};


  $(document).ready(function(){
    $('.product_archive_slider').productArchiveGallery();
    $(document).on('yith_infs_added_elem', function(){
      $('.product_archive_slider').productArchiveGallery();
    });
  });

})(jQuery);